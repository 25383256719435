import React from "react"
import { graphql } from 'gatsby'
import Page from '../components/page';

const Author = (props) => {
  return (
    <>
      <Page title="Der Author" text={props.data.page.html}/>
    </>
  ) 
}

export default Author


export const pageQuery = graphql`
  query AuthorQuery {
    page: markdownRemark(frontmatter: {content: {eq: "page"}, title: {eq: "author"}}) {
      id
      frontmatter {
        title
      }
      rawMarkdownBody
      html
    }
  }
`;